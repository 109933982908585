<template>
  <c-flex w="full" align-items="center" justify-content="center" grid-gap="15px">
    <c-box
      v-for="(_, index) in total"
      :key="index"
      :bg="index + 1 <= current ? 'brand.900' : 'gray.200'"
      rounded="lg"
      h="8px"
      :style="stepStyle"
    />
  </c-flex>
</template>

<script>
export default {
  name: "steps",
  props: {
    current: Number,
    total: Number,
  },
  computed: {
    stepWidth() {
      return 50;
    },
    stepStyle() {
      return {
        width: `calc(100% / ${this.total})`,
      };
    },
  },
};
</script>

<style scoped></style>
